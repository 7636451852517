<template>
  <router-view
    v-slot="{ Component, route }"
    @modus-updated="modusStatusWettbewerb"
    @balken-updated="modusStatusBalken"
    @wall-updated="setWall"
  >
    <transition name="fade" mode="out-in">
      <div :key="route.name" style="min-height: 128px">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
  <nav v-if="pageadmin">
    <span v-if="pageadmin">
      <router-link to="/konfig/">Konfig</router-link> |
      <router-link to="/screen/1">Uhr</router-link>
      |
      <router-link to="/screen/5">Uhr mit Logo</router-link>
      |
      <router-link to="/screen/2">Wettbewerb/Uhr</router-link>
      |
      <router-link to="/screen/6">Wettbewerb | Uhr</router-link>
      |
      <router-link to="/screen/7">mehrere Uhren</router-link>
      |
      <router-link to="/screen/4">Zieldurchlauf</router-link>
      |
      <router-link to="/screen/11">Triadurchlauf</router-link>
      |
      <router-link to="/screen/3">TOP3</router-link>
      |
      <router-link to="/screen/8">Bildergallery</router-link>
      <br />
      <br />
    </span>
    <router-link to="/screen/21">Durchlauf 1</router-link> |
    <router-link to="/screen/22">Durchlauf 2</router-link> |
    <router-link to="/screen/23">Durchlauf 3</router-link> |
    <router-link to="/screen/24">Durchlauf 4</router-link> |
    <router-link to="/screen/29">Durchlauf Ziel</router-link> |
    <router-link to="/screen/31">TOP3-01</router-link> |
    <router-link to="/screen/32">TOP3-02</router-link> |
    <router-link to="/screen/33">TOP3-03</router-link> |
    <router-link to="/screen/34">TOP3-04</router-link> |
    <router-link to="/screen/35">TOP3-05</router-link> |
    <router-link to="/screen/36">TOP3-06</router-link>
  </nav>
  <div
    id="offline"
    v-if="pageadmin && pagewechsel"
    style="
      right: 10px;
      top: 10px;
      position: absolute;
      float: right;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    "
    v-bind:style="{ background: statusColor }"
  ></div>
  <div v-if="websocket && pagewechsel && pageadmin">
    <ul v-for="(eintrag, key) in websocket.uhren" :key="key">
      <li>
        Uhr {{ key
        }}<span v-if="wett_name(key)">&nbsp; {{ wett_name(key).name }}</span
        >: {{ eintrag }}
        <button @click="setUhrWettbewerb(key)">setzen</button>
      </li>
    </ul>

    Aktelle Uhr: {{ websocket.aktuhr }}

    <br />
    <br />
    Setzen:
    <button @click="setPage(1)">Uhr</button>
    <button @click="setPage(5)">Uhr mit Logo</button>
    <button @click="setPage(2)">Wettbewerb/Uhr</button>
    <button @click="setPage(6)">Wettbewerb | Uhr</button>
    <button @click="setPage(7)">mehrere Uhren</button>
    <button @click="setPage(4)">Zieldurchlauf</button>
    <button @click="setPage(3)">TOP3</button>
    <button @click="setPage(8)">Bildergallery</button>
    <button @click="setPage(22)">Durchlauf 2</button>

    <br />
    <br />
    <Multiselect
      v-model="selectedWettbewerbe"
      mode="tags"
      :close-on-select="false"
      :searchable="true"
      :create-option="true"
      :options="alle_wettbewerbe_auswahl"
      @change="set_wettbewerbe_auswahl"
      v-if="currentRouteNummer == 7"
    />
    <br />
    <br />
    <Multiselect
      v-model="selectedWettbewerb"
      :close-on-select="true"
      :options="alle_wettbewerbe_auswahl"
      @change="set_wettbewerb_auswahl"
      v-if="currentRouteNummer == 3"
    />
    <br />
    <br />
    <Multiselect
      v-model="selectedWettbewerbTypSelected"
      :close-on-select="true"
      :options="wettbewerbTyp_auswahl"
      @change="set_wettbewerbtyp_auswahl"
      v-if="currentRouteNummer == 3"
    />
    <br />
    <br />
  </div>
</template>
<script>
//import moment from "moment";
import Store from "./store/index";
//import router from "./router/index";
import Multiselect from "@vueform/multiselect";

import "@/assets/css/tar24.css";

export default {
  name: "App",
  components: { Multiselect },
  data() {
    return {
      //interval: null,
      connection: null,
      websocket: null,
      page: 0,
      pagewechsel: true,
      pageadmin: false,
      statusColor: "#673AB7",
      wall: "klein",
    };
  },

  computed: {
    socket_url() {
      return Store.getters.activeSocket();
    },
    currentRouteNummer() {
      let name = this.$route.name;
      if (name) {
        if (name.split("_")[1]) {
          return name.split("_")[1];
        } else {
          return 0;
        }
      }
      return 0;
    },
    wett_array() {
      return Store.getters.activeWettbewerbe();
    },
    msgs() {
      return this.messages;
    },
    websocket_daten() {
      return this.websocket;
    },
    alle_wettbewerbe_auswahl() {
      if (this.wett_array) {
        let temp = this.wett_array;
        var list = [];
        temp.map(function (value) {
          list.push({ value: value.wett, label: value.name });
        });
        return list;
      }
      return "";
    },
    selectedWettbewerbe() {
      return Store.getters.activeSelectedWettbewerbe();
    },
    selectedWettbewerb() {
      return Store.getters.activeSelectedWettbewerb();
    },
    wettbewerbTyp_auswahl() {
      return Store.getters.activeSelectedWettbewerbTyp();
    },
    selectedWettbewerbTypSelected() {
      return Store.getters.activeSelectedWettbewerbTypSelected();
    },
  },
  methods: {
    wett_name(wettid) {
      if (this.wett_array) {
        let temp = this.wett_array;

        temp = temp.filter(function (element) {
          return element.wett == wettid;
        });
        return temp[0];
      }
      return "";
    },
    set_wettbewerbe_auswahl(e) {
      console.log("daten geändert", e);
      //Store.mutations.setSelectedWettbewerbe(e);
      this.setselectedWettbewerbe(e);
    },
    set_wettbewerb_auswahl(e) {
      console.log("daten geändert", e);
      //Store.mutations.setSelectedWettbewerb(e);
      this.setselectedWettbewerb(e);
    },
    set_wettbewerbtyp_auswahl(e) {
      console.log("daten geändert", e);
      //Store.mutations.setSelectedWettbewerbTypSelected(e);
      this.setselectedWettbewerbTypSelected(e);
    },
    connect_ws() {
      console.log("Starting connection to WebSocket Server");
      this.connection = new WebSocket(this.socket_url);

      this.connection.onopen = (event) => {
        if (event) {
          //console.log(event);
        }
        console.log("Successfully connected to the websocket server...");
        this.statusColor = "#00ff04";
        this.check_page_only();
        this.check_page_admin();
      };
      this.connection.onclose = (event) => {
        console.log(event);
        console.error("NOT connected to the websocket server...");
        this.statusColor = "#c02000";
        this.connection.close();
        setTimeout(() => {
          this.connect_ws();
        }, 2000);
      };
      this.connection.onerror = (event) => {
        console.log(event);
        console.error("NOT connected to the websocket server...");
        this.statusColor = "#c02000";
        this.connection.close();
      };
      this.connection.onmessage = (event) => {
        //console.log("Websocket:", JSON.parse(event.data));
        this.websocket = JSON.parse(event.data);
        Store.mutations.setWebsocket(this.websocket);
        if (
          this.websocket.page &&
          this.page != this.websocket.page &&
          this.pagewechsel
        ) {
          //console.log(this.websocket.page);
          this.page = this.websocket.page;
          //router.push("/screen/" + this.websocket.page);
        }

        if (this.websocket.wall && this.wall != this.websocket.wall) {
          Store.mutations.setWall(this.websocket.wall);
          this.wall = this.websocket.wall;
        }

        if (this.websocket.selectedWettbewerbe) {
          if (
            this.selectedWettbewerbe.toString() ===
            this.websocket.selectedWettbewerbe.toString()
          ) {
            //console.log("check ja");
          } else {
            //console.log("check nein");
            Store.mutations.setSelectedWettbewerbe(
              this.websocket.selectedWettbewerbe
            );
          }
        }

        if (this.websocket.selectedWettbewerb) {
          if (this.selectedWettbewerb === this.websocket.selectedWettbewerb) {
            //console.log("check ja");
          } else {
            //console.log("check nein");
            Store.mutations.setSelectedWettbewerb(
              this.websocket.selectedWettbewerb
            );
          }
        }

        if (this.websocket.selectedWettbewerbTypSelected) {
          if (
            this.selectedWettbewerbTypSelected ===
            this.websocket.selectedWettbewerbTypSelected
          ) {
            //console.log("check ja");
          } else {
            //console.log("check nein");
            Store.mutations.setSelectedWettbewerbTypSelected(
              this.websocket.selectedWettbewerbTypSelected
            );
          }
        }
      };
    },
    setUhrWettbewerb(wettid) {
      var msg = {
        type: "message",
        date: Date.now(),
        aktuhr: parseInt(wettid),
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
    setselectedWettbewerbe(wettids) {
      var msg = {
        type: "message",
        date: Date.now(),
        selectedWettbewerbe: wettids,
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
    // Emit Balken Status
    modusStatusBalken(modusDO) {
      console.log("modusStatusBalken: ", modusDO);
      //this.play();
      //console.log(modusDO.newModus);
      //this.setUhrWettbewerb(modusDO.newModus);
    },
    // Emit Wettbewerb Status
    modusStatusWettbewerb(modusDO) {
      console.log("modusStatusWettbewerb: ", modusDO);
      //this.play();
      this.setUhrWettbewerb(modusDO.newModus);
    },
    setselectedWettbewerb(wettid) {
      console.log("neuer wettbewerb", wettid);
      var msg = {
        type: "message",
        date: Date.now(),
        selectedWettbewerb: wettid,
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
    setselectedWettbewerbTypSelected(typ) {
      var msg = {
        type: "message",
        date: Date.now(),
        selectedWettbewerbTypSelected: typ,
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
    setPage(page) {
      var msg = {
        type: "message",
        date: Date.now(),
        page: parseInt(page),
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
    setWall(wall) {
      var msg = {
        type: "message",
        date: Date.now(),
        wall: wall,
      };

      // Send the msg object as a JSON-formatted string.
      this.connection.send(JSON.stringify(msg));
    },
    check_page_only() {
      console.log("route aktuell", this.$route.fullPath);
      if (
        this.$route.fullPath.split("?")[1] &&
        this.$route.fullPath.split("?")[1] == "only"
      ) {
        this.pagewechsel = false;
      } else {
        this.pagewechsel = true;
      }
    },
    check_page_admin() {
      console.log("route aktuell", this.$route.fullPath);
      if (
        this.$route.fullPath.split("?")[1] &&
        this.$route.fullPath.split("?")[1] == "dsadmin"
      ) {
        this.pageadmin = true;
      } else {
        this.pageadmin = false;
      }
    },
  },
  unmounted() {
    //clearInterval(this.interval);
  },
  mounted() {
    this.connect_ws();
    //this.check_page_only();
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@font-face {
  font-family: "Sofia Sans Extra Condensed";
  src: url("~@/assets/font/sofia_ds.otf");
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media (prefers-color-scheme: dark) {
  body {
    background: black;
    color: white;
  }

  button {
    color: #bebebe;
  }
  #app {
    color: white !important;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
body {
  line-height: 1;
  /*background: #04f404;*/
  background: black;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
#app {
  font-family: "Sofia Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
